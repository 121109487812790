import { useEffect, useRef, useState } from "react";
import { Button, Col, Container, Form, Modal, OverlayTrigger, Row, Table, Tooltip } from "react-bootstrap";
import Select from "react-select";
import { botApis } from "../services/botApis";
import Skeleton from "react-loading-skeleton";
import { exceptionHandling, formatBalance } from "../Common/CommonComponents";
import { useNavigate } from "react-router-dom";
import ManualBuy from "./ManualBuy";
import ManualSell from "./ManualSell";
import Loader from "../Common/Loader";
import { connectionWeb3 } from "../config/solanaWeb3";

const Home = () => {
    const navigate = useNavigate();
    const [manualSellData, setManualSellData] = useState({ amountPercentage: 0, tokenAddress: "", errors: {} });
    const [showManualBuy, setShowManualBuy] = useState(false);
    const [showManualSell, setShowManualSell] = useState(false);
    const [copied, setCopied] = useState({});
    const [telegramConnectedStatus, setTelegramConnectedStatus] = useState(false);
    const [buyBotStatus, setBuyBotStatus] = useState(false);
    const [sellBotStatus, setSellBotStatus] = useState(false);


    const [latestTelegramMessages, setLatestTelegramMessages] = useState([]);
    const [telegramNextPage, setTelegramNextPage] = useState(0);
    const telegramMessagesRef = useRef();
    const [isCallTelegramObserverTop, setIsCallTelegramObserverTop] = useState(false);
    const telegramMessagesObserverTop = useRef();
    const isCallTelegramObserverTopRef = useRef(false);

    const [latestTokens, setLatestTokens] = useState([]);
    const [nextPage, setNextPage] = useState(0);
    const tokenLogsRef = useRef();
    const [isCallObserverTop, setIsCallObserverTop] = useState(false);
    const tokenLogsObserverTop = useRef();
    const isCallObserverTopRef = useRef(false);
    const [adminData, setAdminData] = useState({});
    const [error, setError] = useState({});


    const [latestTransactions, setLatestTransactions] = useState([]);
    const [latestTransactionsNextPage, setLatestTransactionsNextPage] = useState(0);
    const latestTransactionsRef = useRef();
    const [isCallLatestTransactionsObserverTop, setIsCallLatestTransactionsObserverTop] = useState(false);
    const latestTransactionsObserverTop = useRef();
    const isCallLatestTransactionsObserverTopRef = useRef(false);


    const [boughtTokens, setBoughtTokens] = useState([]);
    const [boughtTokensNextPage, setBoughtTokensNextPage] = useState(0);
    const boughtTokensRef = useRef();
    const [isCallBoughtTokensObserverTop, setIsCallBoughtTokensObserverTop] = useState(false);
    const boughtTokensObserverTop = useRef();
    const isCallBoughtTokensObserverTopRef = useRef(false);
    const { getBalance } = connectionWeb3();
    const balanceTimeOutRef = useRef(0);
    const [inProcess, setInProcess] = useState(false);
    const [serverBotStatus, setServerBotStatus] = useState(false);
    const adminDataRef = useRef();

    useEffect(() => {
        init();
    }, [])

    useEffect(() => {
        const interval = setInterval(() => {
            if (telegramConnectedStatus) {
                setIsCallObserverTop(false);
                isCallObserverTopRef.current = false;
                setIsCallTelegramObserverTop(false);
                isCallTelegramObserverTopRef.current = false;
            }
            if (buyBotStatus) {
                isCallLatestTransactionsObserverTopRef.current = false;
                setIsCallLatestTransactionsObserverTop(false);

                isCallBoughtTokensObserverTopRef.current = false;
                setIsCallBoughtTokensObserverTop(false);
            }
            checkBalance();
        }, 5000);
        return () => clearInterval(interval);
    }, [telegramConnectedStatus, buyBotStatus]);



    async function checkBalance() {
        if (adminDataRef.current?.wallet_address && balanceTimeOutRef.current == 60000) {
            const balance = await getBalance(adminDataRef.current.wallet_address);
            console.log("balance-------->", balance);
            setAdminData({ ...adminDataRef.current, balance: balance });

            if (!inProcess) {
                await botApis.startInProcess();
                getLatestTransactions(0, "TOP");
                getBoughtTokens(0, "TOP");
            }
            balanceTimeOutRef.current = 0;
        }
        balanceTimeOutRef.current = balanceTimeOutRef.current + 5000;
    }


    async function init() {
        getAdminData();
        checkServerBotConnection();
        checkTelegramConnection();
        checkBuyBotConnection();
        checkSellBotConnection();
        getLatestTokens(0, "TELEGRAM_TOP");
        getLatestTokens(0, "TOP");
        getLatestTransactions(0, "TOP");
        getBoughtTokens(0, "TOP");
    }

    async function getAdminData() {
        try {
            const response = await botApis.getAdminData();
            if (response.status === 200) {
                const responseData = { ...response.data, max_lamports: (Number(response.data.max_lamports) / 10 ** 9) };
                setAdminData(responseData);
                adminDataRef.current = responseData;
                setLossPercentageStatus(responseData.loss_status);
            } else {
                throw new Error('Failed to fetch data');
            }
        } catch (error) {
            exceptionHandling(error);
            console.error('Error fetching data:', error);
        }
    }

    const [buyOptionLoader, setBuyOptionLoader] = useState(false);
    const [sellOptionLoader, setSellOptionLoader] = useState(false);
    const [userLoader, setUserLoader] = useState(false);

    async function updateUser(type) {
        try {
            let errorTemp = { ...error }, status = false;
            if (!adminData.token_buy_amount || adminData.token_buy_amount == 0) {
                status = true;
                errorTemp.token_buy_amount = "Amount to buy is required";
            }
            if (!adminData.wallet_address || !adminData.wallet_address.trim()) {
                status = true;
                errorTemp.wallet_address = "Wallet address is required";
            }
            if (!adminData.wallet_private_key || !adminData.wallet_private_key.trim()) {
                status = true;
                errorTemp.wallet_private_key = "Wallet private key is required";
            }

            if (!adminData.max_lamports || (adminData.max_lamports && adminData.max_lamports <= 0)) {
                status = true;
                errorTemp.max_lamports = "Max Lamports is required";
            }

            if (!adminData.slippage || (adminData.slippage && adminData.slippage <= 0)) {
                status = true;
                errorTemp.slippage = "Slippage is required";
            }

            if (!adminData.loss_percentage || adminData.loss_percentage == 0) {
                status = true;
                errorTemp.loss_percentage = "Loss percentage required";
            }
            if (!adminData.profit_percentage || adminData.profit_percentage == 0) {
                status = true;
                errorTemp.profit_percentage = "Profit percentage required";
            }

            if (status) {
                setError(errorTemp);
                return;
            }
            if (type == "BUY") {
                setBuyOptionLoader(true);
            }
            if (type == "USER") {
                setUserLoader(true)
            }
            if (type == "SELL") {
                setSellOptionLoader(true);
            }
            const params = {
                score_indicator: adminData.score_indicator,
                token_buy_amount: adminData.token_buy_amount,
                wallet_address: adminData.wallet_address,
                wallet_private_key: adminData.wallet_private_key,
                loss_percentage: adminData.loss_percentage,
                profit_percentage: adminData.profit_percentage,
                max_lamports: (Number(adminData.max_lamports) * 10 ** 9),
                slippage: Number(adminData.slippage)
            }
            const response = await botApis.updateUser(params);
            if (response.status === 200) {
                setAdminData({ ...response.data, max_lamports: (Number(response.data.max_lamports) / 10 ** 9) });
                setBuyOptionLoader(false);
                setUserLoader(false);
                setSellOptionLoader(false);
            } else {
                throw new Error('Failed to fetch data');
            }
        } catch (error) {
            setBuyOptionLoader(false);
            setUserLoader(false);
            setSellOptionLoader(false);
            exceptionHandling(error);
            console.error('Error fetching data:', error);
        }
    }

    async function checkTelegramConnection() {
        try {
            const response = await botApis.checkTelegramConnection();
            if (response.status === 200) {
                setTelegramConnectedStatus(response.data.status);
            } else {
                throw new Error('Failed to fetch data');
            }
        } catch (error) {
            exceptionHandling(error);
            console.error('Error fetching data:', error);
        }
    }

    async function startTelegramConnection() {
        try {
            const response = await botApis.startTelegramConnection();
            if (response.status === 200) {
                await checkTelegramConnection();
            } else {
                throw new Error('Failed to fetch data');
            }
        } catch (error) {
            exceptionHandling(error);
            console.error('Error fetching data:', error);
        }
    }

    async function stopTelegramConnection() {
        try {
            const response = await botApis.stopTelegramConnection();
            if (response.status === 200) {
                await checkTelegramConnection();
            } else {
                throw new Error('Failed to fetch data');
            }
        } catch (error) {
            exceptionHandling(error);
            console.error('Error fetching data:', error);
        }
    }


    async function checkBuyBotConnection() {
        try {
            let response = await botApis.checkPool();
            response = await botApis.checkBuy();
            if (response.status === 200) {
                setBuyBotStatus(response.data.status);
                setInProcess(response.data.status);
            } else {
                throw new Error('Failed to fetch data');
            }
        } catch (error) {
            exceptionHandling(error);
            console.error('Error fetching data:', error);
        }
    }

    async function startBuyBotConnection() {
        try {
            let response = await botApis.startPool();
            response = await botApis.startBuy();
            if (response.status === 200) {
                await checkBuyBotConnection();
            } else {
                throw new Error('Failed to fetch data');
            }
        } catch (error) {
            exceptionHandling(error);
            console.error('Error fetching data:', error);
        }
    }

    async function stopBuyBotConnection() {
        try {
            let response = await botApis.stopPool();
            response = await botApis.stopBuy();
            if (response.status === 200) {
                await checkBuyBotConnection();
            } else {
                throw new Error('Failed to fetch data');
            }
        } catch (error) {
            exceptionHandling(error);
            console.error('Error fetching data:', error);
        }
    }



    async function checkSellBotConnection() {
        try {
            const response = await botApis.checkSell();
            if (response.status === 200) {
                setSellBotStatus(response.data.status);
            } else {
                throw new Error('Failed to fetch data');
            }
        } catch (error) {
            exceptionHandling(error);
            console.error('Error fetching data:', error);
        }
    }

    async function startSellBotConnection() {
        try {
            const response = await botApis.startSell();
            if (response.status === 200) {
                await checkSellBotConnection();
            } else {
                throw new Error('Failed to fetch data');
            }
        } catch (error) {
            exceptionHandling(error);
            console.error('Error fetching data:', error);
        }
    }

    async function stopSellBotConnection() {
        try {
            const response = await botApis.stopSell();
            if (response.status === 200) {
                await checkSellBotConnection();
            } else {
                throw new Error('Failed to fetch data');
            }
        } catch (error) {
            exceptionHandling(error);
            console.error('Error fetching data:', error);
        }
    }

    async function getLatestTokens(page, callType) {
        try {
            const response = await botApis.getLatestTokens(page);
            if (response.status === 200) {
                let responseData = response.data;
                if (callType == "BOTTOM" || callType == "TELEGRAM_BOTTOM") {
                    let latestTokensTemp = callType == "BOTTOM" ? [...latestTokens.data] : [...latestTelegramMessages.data];
                    latestTokensTemp.push(...responseData.data);
                    responseData.data = latestTokensTemp;
                }
                if (callType == "TOP") {
                    setNextPage(0);
                }
                if (callType == "TELEGRAM_TOP") {
                    setTelegramNextPage(0);
                }
                if (callType == "BOTTOM" || callType == "TOP") {
                    setLatestTokens(responseData);
                } else {
                    setLatestTelegramMessages(responseData);
                }
            } else {
                throw new Error('Failed to fetch data');
            }
        } catch (error) {
            exceptionHandling(error);
            console.error('Error fetching data:', error);
        }
    }


    async function getLatestTransactions(page, callType) {
        try {
            const response = await botApis.getLatestTransactions(page);
            if (response.status === 200) {
                let responseData = response.data;
                if (callType == "BOTTOM") {
                    let latestTransactionsTemp = [...latestTransactions.data];
                    latestTransactionsTemp.push(...responseData.data);
                    responseData.data = latestTransactionsTemp;
                }
                if (callType == "TOP") {
                    setLatestTransactionsNextPage(0);
                }
                setLatestTransactions(responseData);
            } else {
                throw new Error('Failed to fetch data');
            }
        } catch (error) {
            exceptionHandling(error);
            console.error('Error fetching data:', error);
        }
    }


    async function getBoughtTokens(page, callType) {
        try {
            const response = await botApis.getBoughtTokens(page);
            if (response.status === 200) {
                let responseData = response.data;
                if (callType == "BOTTOM") {
                    let boughtTokensTemp = [...boughtTokens.data];
                    boughtTokensTemp.push(...responseData.data);
                    responseData.data = boughtTokensTemp;
                }
                if (callType == "TOP") {
                    setBoughtTokensNextPage(0);
                }
                setBoughtTokens(responseData);
            } else {
                throw new Error('Failed to fetch data');
            }
        } catch (error) {
            exceptionHandling(error);
            console.error('Error fetching data:', error);
        }
    }


    function sellTokenData(amount, tokenAddress) {
        setManualSellData({ amount, tokenAddress, sellBy: "HOME", amountPercentage: 100 });
        setShowManualSell(true);
    }


    function getTransactions() {
        getLatestTransactions(0, "TOP");
        getBoughtTokens(0, "TOP");

    }

    const options = [
        { value: "Good", label: "Good" },
        { value: "Neutral", label: "Neutral" },
        { value: "Bad", label: "Bad" },
    ];

    function getScoreClass(scoreType) {
        switch (scoreType) {
            case "Good":
                return "good-tokens token-score";
            case "Neutral":
                return "neutral-tokens token-score";
            case "Bad":
                return "bad-tokens token-score";
            case "Manual Buy Pool":
                return "good-tokens token-score";
            default:
                return "token-front token-score";
        }
    }


    async function switchTelegramConnected(isConnected) {
        setTelegramConnectedStatus(!isConnected)
        if (isConnected) {
            await stopTelegramConnection();
        } else {
            await startTelegramConnection();
        }
    }

    async function switchBuyBot(isConnected) {
        setBuyBotStatus(!isConnected);
        if (isConnected) {
            await stopBuyBotConnection();
        } else {
            await startBuyBotConnection();
        }
    }

    async function switchSellBot(isConnected) {
        setSellBotStatus(!isConnected);
        if (isConnected) {
            await stopSellBotConnection();
        } else {
            await startSellBotConnection();
        }
    }

    const copyTokenAddress = (token, index) => {
        navigator.clipboard.writeText(token)
            .then(() => {
                setCopied({ ...copied, [index]: true });
                setTimeout(() => setCopied({ ...copied, [index]: false }), 2000); // Reset copied state after 2 seconds
            })
            .catch((error) => console.error('Failed to copy:', error));
    };




    useEffect(() => {
        tokenLogsObserverTop.current = new IntersectionObserver(handleTokenLogsObserverTop, {
            root: null,
            rootMargin: '20px',
            threshold: 1.0,
        });
        if (latestTokens?.data?.length > 0) {
            tokenLogsObserverTop.current.observe(document.querySelector('.latest-tokens-logs-top'));
        }

    }, [isCallObserverTop, isCallObserverTopRef.current, latestTokens, latestTokens?.data, telegramConnectedStatus]);

    const handleTokenLogsObserverTop = async (entities) => {
        const target = entities[0];
        if (target.isIntersecting && !isCallObserverTopRef.current && telegramConnectedStatus) {
            isCallObserverTopRef.current = true;
            setIsCallObserverTop(true);
            await getLatestTokens(0, "TOP");
        }
    };

    const onScrollTokenLogs = async () => {
        if (tokenLogsRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = tokenLogsRef.current;
            if (scrollTop + clientHeight === scrollHeight) {
                let page = nextPage + 1, totalPages = Number(latestTokens.total_pages);
                if (page < totalPages) {
                    await getLatestTokens(page, "BOTTOM");
                    setNextPage(page);
                }
            }
        }
    };


    useEffect(() => {
        telegramMessagesObserverTop.current = new IntersectionObserver(handleTelegramMessagesObserverTop, {
            root: null,
            rootMargin: '20px',
            threshold: 1.0,
        });
        if (latestTokens?.data?.length > 0) {
            telegramMessagesObserverTop.current.observe(document.querySelector('.latest-telegram-messages-top'));
        }

    }, [isCallTelegramObserverTop, isCallTelegramObserverTopRef.current, latestTelegramMessages, latestTelegramMessages?.data, telegramConnectedStatus]);

    const handleTelegramMessagesObserverTop = async (entities) => {
        const target = entities[0];
        if (target.isIntersecting && !isCallTelegramObserverTopRef.current && telegramConnectedStatus) {
            isCallTelegramObserverTopRef.current = true;
            setIsCallTelegramObserverTop(true);
            await getLatestTokens(0, "TELEGRAM_TOP");
        }
    };

    const onScrollTelegramMessages = async () => {
        if (telegramMessagesRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = telegramMessagesRef.current;
            if (scrollTop + clientHeight === scrollHeight) {
                let page = telegramNextPage + 1, totalPages = Number(latestTelegramMessages.total_pages);
                if (page < totalPages) {
                    await getLatestTokens(page, "TELEGRAM_BOTTOM");
                    setTelegramNextPage(page);
                }
            }
        }
    };



    useEffect(() => {
        latestTransactionsObserverTop.current = new IntersectionObserver(handleLatestTransactionsObserverTop, {
            root: null,
            rootMargin: '20px',
            threshold: 1.0,
        });
        if (latestTransactions?.data?.length > 0) {
            latestTransactionsObserverTop.current.observe(document.querySelector('.latest-transactions-top'));
        }

    }, [isCallLatestTransactionsObserverTop, isCallLatestTransactionsObserverTopRef.current, latestTransactions, latestTransactions?.data, buyBotStatus]);

    const handleLatestTransactionsObserverTop = async (entities) => {
        const target = entities[0];
        if (target.isIntersecting && !isCallLatestTransactionsObserverTopRef.current && buyBotStatus) {
            isCallLatestTransactionsObserverTopRef.current = true;
            setIsCallLatestTransactionsObserverTop(true);
            await getLatestTransactions(0, "TOP");
        }
    };

    const onScrollLatestTransactions = async () => {
        if (latestTransactionsRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = latestTransactionsRef.current;
            if (scrollTop + clientHeight === scrollHeight) {
                let page = latestTransactionsNextPage + 1, totalPages = Number(latestTransactions.total_pages);
                if (page < totalPages) {
                    await getLatestTransactions(page, "BOTTOM");
                    setLatestTransactionsNextPage(page);
                }
            }
        }
    };


    useEffect(() => {
        boughtTokensObserverTop.current = new IntersectionObserver(handleBoughtTokensObserverTop, {
            root: null,
            rootMargin: '20px',
            threshold: 1.0,
        });
        if (boughtTokens?.data?.length > 0) {
            boughtTokensObserverTop.current.observe(document.querySelector('.bought-tokens-top'));
        }

    }, [isCallBoughtTokensObserverTop, isCallBoughtTokensObserverTopRef.current, boughtTokens, boughtTokens?.data, buyBotStatus]);

    const handleBoughtTokensObserverTop = async (entities) => {
        const target = entities[0];
        if (target.isIntersecting && !isCallBoughtTokensObserverTopRef.current && buyBotStatus) {
            isCallBoughtTokensObserverTopRef.current = true;
            setIsCallBoughtTokensObserverTop(true);
            await getBoughtTokens(0, "TOP");
        }
    };

    const onScrollBoughtTokens = async () => {
        if (boughtTokensRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = boughtTokensRef.current;
            if (scrollTop + clientHeight === scrollHeight) {
                let page = boughtTokensNextPage + 1, totalPages = Number(boughtTokens.total_pages);
                if (page < totalPages) {
                    await getBoughtTokens(page, "BOTTOM");
                    setBoughtTokensNextPage(page);
                }
            }
        }
    };


    const maskAddress = (address, characters = 5) => {
        const startChars = address.substring(0, characters); // Get the first 6 characters
        const endChars = address.substring(address.length - characters); // Get the last 6 characters
        return `${startChars}.....${endChars}`; // Combine with dots in between
    };

    function convertUtcToLocal(utcTimeString) {
        // Parse the UTC time string
        const utcDate = new Date(utcTimeString);

        // Get the time zone offset in minutes
        const offsetMinutes = utcDate.getTimezoneOffset();

        // Convert UTC time to local time by adding the offset
        const localDate = new Date(utcDate.getTime() - (offsetMinutes * 60000));

        // Format the local time
        const options = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: true // Include AM/PM indicator
        };
        const formattedLocalTime = localDate.toLocaleString(undefined, options);

        return formattedLocalTime;
    }

    function logout() {
        localStorage.clear();
        navigate("/");
    }

    async function switchServerBot(isConnected) {
        setServerBotStatus(!isConnected);
        if (isConnected) {
            await stopServerBotConnection();
        } else {
            await startServerBotConnection();
        }
    }

    async function checkServerBotConnection() {
        try {
            const response = await botApis.checkManualPool();
            if (response.status === 200) {
                setServerBotStatus(response.data.status);
            } else {
                throw new Error('Failed to fetch data');
            }
        } catch (error) {
            exceptionHandling(error);
            console.error('Error fetching data:', error);
        }
    }

    async function startServerBotConnection() {
        try {
            const response = await botApis.startManualPool();
            if (response.status === 200) {
                await checkServerBotConnection();
                await startTelegramConnection();
            } else {
                throw new Error('Failed to fetch data');
            }
        } catch (error) {
            exceptionHandling(error);
            console.error('Error fetching data:', error);
        }
    }

    async function stopServerBotConnection() {
        try {
            const response = await botApis.stopManualPool();
            if (response.status === 200) {
                await checkServerBotConnection();
                await stopBuyBotConnection();
                await stopTelegramConnection();
                await stopSellBotConnection();
            } else {
                throw new Error('Failed to fetch data');
            }
        } catch (error) {
            exceptionHandling(error);
            console.error('Error fetching data:', error);
        }
    }

    const [lossPercentageStatus, setLossPercentageStatus] = useState(false);


    async function lossStatus(status) {
        try {
            const params = { loss_status: !status }
            setLossPercentageStatus(!status);
            const response = await botApis.lossStatus(params);
            if (response.status === 200) {
                console.log("response", response);
                setLossPercentageStatus(response.data.loss_status);
            } else {
                throw new Error('Failed to fetch data');
            }
        } catch (error) {
            exceptionHandling(error);
            console.error('Error fetching data:', error);
        }
    }

    return (
        <>
            <section className="top-area">
                <Container fluid>
                    <div className="head-top-right">

                        {(adminData?.wallet_address) ?
                            <h6 className="main-wallet-top"> {maskAddress(adminData?.wallet_address)}
                                <img src={require("../assets/images/balance-icon.svg").default} alt="img" />
                                <span>  {adminData?.balance && formatBalance(adminData?.balance)} SOL </span>
                            </h6>
                            : <Skeleton className="main-wallet-top mb-2" height={25} width={200} />}


                        <h6 className="main-wallet-top cursor-pointer" onClick={() => logout()}>
                            <i class="fa fa-sign-out" aria-hidden="true"></i>
                        </h6>
                    </div>
                    <Row className="mb-3">
                        <Col xl={8}>
                            <Row>
                                <Col md={4}>
                                    <div className="top-boxes mb-3">
                                        <Form>
                                            <div className="selling-top-right mb-0">
                                                <h5>Server Status</h5>
                                                <div className="selling-option-top">
                                                    <h6>Stop</h6>
                                                    <Form.Check
                                                        type="switch"
                                                        id="custom-switch"
                                                        checked={serverBotStatus}
                                                        onChange={() => switchServerBot(serverBotStatus)} />
                                                    <h6>Start</h6>
                                                </div>
                                            </div>
                                            <Row>
                                                <Col sm={6} md={12} lg={12} className="padding-right">
                                                    <Form.Group className="mb-1" controlId="formBasicEmail">
                                                        <Form.Label>Account Public key</Form.Label>
                                                        <Form.Control type="text" placeholder="Enter Account Public key" value={adminData?.wallet_address} onChange={(e) => setAdminData({ ...adminData, wallet_address: e.target.value })} />
                                                        {(!adminData?.wallet_address || !adminData?.wallet_address.trim()) && error.wallet_address && <p className="error">{error.wallet_address}</p>}
                                                    </Form.Group>
                                                </Col>
                                                <Col sm={6} md={12} lg={12} >
                                                    <Form.Group className="mb-1" controlId="formBasicPassword">
                                                        <Form.Label>Account Private key</Form.Label>
                                                        <Form.Control type="text" placeholder="Enter Account Private key" value={adminData?.wallet_private_key} onChange={(e) => setAdminData({ ...adminData, wallet_private_key: e.target.value })} />
                                                        {(!adminData?.wallet_private_key || !adminData?.wallet_private_key.trim()) && error.wallet_private_key && <p className="error">{error.wallet_private_key}</p>}
                                                    </Form.Group>
                                                </Col>
                                                <Col sm={6} md={12} lg={12} >
                                                    <Row>
                                                        <Col sm={6} md={6} lg={6} >
                                                            <Form.Group controlId="formBasicPassword">
                                                                <Form.Label>Max Lamports/Gas Fee</Form.Label>
                                                                <Form.Control type="text" placeholder="Enter max Lamports" value={adminData?.max_lamports} onChange={(e) => setAdminData({ ...adminData, max_lamports: e.target.value })} />
                                                                {(!adminData?.max_lamports) && error.max_lamports && <p className="error">{error.max_lamports}</p>}
                                                            </Form.Group>
                                                        </Col>
                                                        <Col sm={6} md={6} lg={6} >
                                                            <Form.Group controlId="formBasicPassword">
                                                                <Form.Label>slippage (Percentage)</Form.Label>
                                                                <Form.Control type="text" placeholder="Enter slippage" value={adminData?.slippage} onChange={(e) => setAdminData({ ...adminData, slippage: e.target.value })} />
                                                                {(!adminData?.slippage) && error.slippage && <p className="error">{error.slippage}</p>}
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                </Col>

                                            </Row>

                                            <Button className="mb-2" type="button" variant="unset" disabled={userLoader} onClick={() => updateUser("USER")}>
                                                {userLoader ? <Loader loaderType={"COLOR_RING"} width={15} height={15} /> : "Save"}
                                            </Button>

                                        </Form>
                                    </div>

                                </Col>

                                <Col md={4}>
                                    <div className="top-boxes selling-top-box mb-3">
                                        <Form>
                                            <div className="selling-top-right">
                                                <h5>Buy Option:</h5>
                                                <div className="selling-option-top">
                                                    <h6>Stop</h6>
                                                    <Form.Check
                                                        type="switch"
                                                        id="custom-switch"
                                                        checked={buyBotStatus}
                                                        onChange={() => switchBuyBot(buyBotStatus)} />
                                                    <h6>Start</h6>
                                                </div>
                                            </div>

                                            <Row>
                                                <Col sm={6} md={12} lg={12}>
                                                    <Form.Group
                                                        className="mb-2"
                                                        controlId="score_indicator"

                                                    >
                                                        <Form.Label>Score Indicator</Form.Label>
                                                        <Select options={options} styles={{
                                                            control: (base, state) => ({
                                                                // ...base,
                                                                background: "#0000004d",
                                                                border: "1px solid #ffffff40",
                                                                // padding: "4px",
                                                                borderRadius: "5px",
                                                                display: "flex"
                                                            }),
                                                            placeholder: (base, state) => ({
                                                                ...base,
                                                                color: "#fff",

                                                            }),
                                                            input: (base, state) => ({
                                                                ...base,
                                                                color: "white"
                                                            })
                                                        }}
                                                            value={{ value: adminData?.score_indicator, label: adminData?.score_indicator }}
                                                            onChange={(e) => setAdminData({ ...adminData, score_indicator: e.value })}
                                                        />
                                                    </Form.Group>
                                                </Col>

                                                <Col sm={6} md={12} lg={12}>
                                                    <Form.Group controlId="token_buy_amount " className="mb-3">
                                                        <Form.Label>Amount to Buy (SOL)</Form.Label>
                                                        <Form.Control type="Number" placeholder="Enter Amount" value={adminData?.token_buy_amount} min={0} onChange={(e) => setAdminData({ ...adminData, token_buy_amount: e.target.value })} />
                                                        {(!adminData?.token_buy_amount || adminData?.token_buy_amount == 0) && error.token_buy_amount && <p className="error">{error.token_buy_amount}</p>}
                                                    </Form.Group>
                                                </Col>

                                            </Row>
                                        </Form>
                                        <div className="buttons-bottom mb-2">
                                            <Button type="button" variant="unset" onClick={() => setShowManualBuy(true)}>Manual Buy</Button>
                                            <Button type="button" variant="unset" disabled={buyOptionLoader} onClick={() => updateUser("BUY")}>
                                                {buyOptionLoader ? <Loader loaderType={"COLOR_RING"} width={15} height={15} /> : "Save"}
                                            </Button>
                                        </div>

                                    </div>
                                </Col>

                                <Col md={4}>
                                    <div className="top-boxes selling-top-box mb-3">
                                        <Form>
                                            <div className="selling-top-right">
                                                <h5>Selling Option:</h5>
                                                <div className="selling-option-top">
                                                    <h6>Manual</h6>
                                                    <Form.Check
                                                        type="switch"
                                                        id="custom-switch"
                                                        checked={sellBotStatus}
                                                        onChange={() => switchSellBot(sellBotStatus)} />
                                                    <h6>Automatic</h6>
                                                </div>
                                            </div>

                                            <Row>
                                                <Col sm={6} md={12} lg={12}>
                                                    <Form.Group controlId="StopLossinPercentage " className="mb-2 selling-inner">
                                                        <Form.Label className="stop-loss-percentage">
                                                            <Form.Check
                                                                type="checkbox"
                                                                id="custom-switch"
                                                                checked={lossPercentageStatus}
                                                                onChange={() => lossStatus(lossPercentageStatus)} />Stop Loss in Percentage</Form.Label>
                                                        <Form.Control type="number" disabled={!lossPercentageStatus} placeholder="Enter Percentage" value={adminData?.loss_percentage} min={0} max={100} onChange={(e) =>
                                                            setAdminData({ ...adminData, loss_percentage: e.target.value <= 100 ? e.target.value : adminData.loss_percentage })} />
                                                        {(!adminData?.loss_percentage || adminData?.loss_percentage == 0) && error.loss_percentage && <p className="error">{error.loss_percentage}</p>}
                                                    </Form.Group>
                                                </Col>

                                                <Col sm={6} md={12} lg={12}>

                                                    <Form.Group controlId="ProfitinPercentage " className="mb-3 selling-inner profit-percentage">
                                                        <Form.Label>Profit in Percentage</Form.Label>
                                                        <Form.Control type="number" placeholder="Enter Percentage" value={adminData?.profit_percentage} min={0} onChange={(e) =>
                                                            setAdminData({ ...adminData, profit_percentage: e.target.value })
                                                        } />
                                                        {(!adminData?.profit_percentage || adminData?.profit_percentage == 0) && error.profit_percentage && <p className="error">{error.profit_percentage}</p>}
                                                    </Form.Group>
                                                </Col>

                                            </Row>
                                        </Form>
                                        <div className="buttons-bottom mb-2">
                                            <Button type="button" variant="unset" onClick={() => { setManualSellData({ sellBy: "" }); setShowManualSell(true) }}>Manual Sell</Button>
                                            <Button type="button" variant="unset" disabled={sellOptionLoader} onClick={() => updateUser("SELL")}>
                                                {sellOptionLoader ? <Loader loaderType={"COLOR_RING"} width={15} height={15} /> : "Save"}
                                            </Button>
                                        </div>

                                    </div>
                                </Col>
                            </Row>


                            <Row>
                                <Col md={8} lg={8} xl={8}>

                                    <div className="transaction-logs-left mb-3">
                                        <h6>Transaction Logs</h6>
                                        <div className="transaction-logs snipperbot-transaction transaction-body" onScroll={onScrollLatestTransactions} ref={latestTransactionsRef}>
                                            <Table >
                                                <thead>
                                                    <tr>
                                                        <th>Name</th>
                                                        <th>Address</th>
                                                        <th>Transactions ID</th>
                                                        <th>Action</th>
                                                        <th>Token Amount</th>
                                                        <th>Amount </th>
                                                        <th>Action Start</th>
                                                        <th>Action End</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <div className="latest-transactions-top"></div>
                                                    {(latestTransactions.length <= 0) ? (<tr>
                                                        <td ><Skeleton height={15} width={80} count={20} /></td>
                                                        <td ><Skeleton height={15} width={90} count={20} /></td>
                                                        <td ><Skeleton height={15} width={90} count={20} /></td>
                                                        <td ><Skeleton height={15} width={80} count={20} /></td>
                                                        <td ><Skeleton height={15} width={60} count={20} /></td>
                                                        <td ><Skeleton height={15} width={60} count={20} /></td>
                                                        <td ><Skeleton height={15} width={80} count={20} /></td>
                                                        <td ><Skeleton height={15} width={80} count={20} /></td>
                                                    </tr>) : latestTransactions?.data?.map((item, index) => {

                                                        return (<tr>
                                                            <td>{item.token.name}</td>
                                                            <td>{maskAddress(item.token.token)}
                                                                <OverlayTrigger
                                                                    placement="top"
                                                                    overlay={<Tooltip id={`tooltip-${index}`}>{copied[`latestTransactions${index}`] ? "Copied" : "Copy to Clipboard"}</Tooltip>}
                                                                >
                                                                    <i class="far fa-copy" onClick={() => copyTokenAddress(item.token.token, `latestTransactions${index}`)}
                                                                    ></i>
                                                                </OverlayTrigger></td>
                                                            <td>{maskAddress(item.txid)}
                                                                <OverlayTrigger
                                                                    placement="top"
                                                                    overlay={<Tooltip id={`tooltip-${index}`}>{copied[`txid${index}`] ? "Copied" : "Copy to Clipboard"}</Tooltip>}
                                                                >
                                                                    <i class="far fa-copy" onClick={() => copyTokenAddress(item.txid, `txid${index}`)}
                                                                    ></i>
                                                                </OverlayTrigger></td>
                                                            <td className={item?.transaction_status && item?.last_try ? "buy-action" : item?.last_try ? "error" : ""}>{item.status} </td>
                                                            <td>{(item.status == "Manual Sell" || item.status == "Sell") ? item.sol_given : item?.token_amount} {item.token.symbol}</td>
                                                            <td>{(item.status == "Manual Sell" || item.status == "Sell") ? item?.token_amount : item.sol_given} SOL</td>
                                                            <td>{convertUtcToLocal(item.action_start_at)}</td>
                                                            <td>{convertUtcToLocal(item.created_at)}</td>
                                                        </tr>)
                                                    })}
                                                </tbody>
                                            </Table>

                                        </div>
                                    </div>
                                </Col>
                                <Col md={4} lg={4} xl={4}>
                                    <div className="token-logs">
                                        <div className="token-logs-top">
                                            <h6>Bought Token Logs</h6>
                                        </div>
                                        <div className="transaction-logs transaction-body token-body-bottom" onScroll={onScrollBoughtTokens} ref={boughtTokensRef}>
                                            <Table >
                                                <thead>
                                                    <tr>
                                                        <th>Token Address</th>
                                                        <th className="token-score">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <div className="bought-tokens-top"></div>
                                                    {(boughtTokens.length <= 0) ? (<tr>
                                                        <td className="token-front "><Skeleton height={15} width={250} count={20} /></td>
                                                        <td className="token-front score-board-right"><Skeleton height={15} width={50} count={20} /></td>
                                                    </tr>) : boughtTokens?.data?.map((item, index) => {
                                                        return (<tr>
                                                            <td className="token-front token-address-area">{maskAddress(item.transaction.token.token)}
                                                                <h6>
                                                                    (<p>Balance:</p>
                                                                    {Number(formatBalance(item.transaction.token.balance)) < 0.01 ? "<0.01" : formatBalance(item.transaction.token.balance)} {item.transaction.token.symbol})
                                                                </h6>
                                                            </td>
                                                            <td className="sell-btn"><Button variant="unset" type="button" onClick={() => sellTokenData(formatBalance(item.transaction.token.balance), item.transaction.token.token)}>Sell</Button></td>
                                                        </tr>)
                                                    })}

                                                </tbody>
                                            </Table>
                                        </div>

                                        <div className="token-logs-top mt-3">
                                            <h6>All Token Logs</h6>
                                        </div>

                                        <div className="transaction-logs transaction-body token-mis-area mb-3" ref={tokenLogsRef} onScroll={onScrollTokenLogs}>

                                            <Table>
                                                <thead>
                                                    <tr>
                                                        <th>Token Address</th>
                                                        <th className="token-score">Score</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <div className="latest-tokens-logs-top"></div>
                                                    {(latestTokens.length <= 0) ? (<tr>
                                                        <td className="token-front "><Skeleton height={15} width={250} count={20} /></td>
                                                        <td className="token-front score-board-right"><Skeleton height={15} width={50} count={20} /></td>
                                                    </tr>) :
                                                        latestTokens?.data?.map((message, index) => {
                                                            return (<tr className={message.is_latest ? "latest_token" : ""}>
                                                                <td className="token-front">{maskAddress(message.token, 15)}
                                                                    <OverlayTrigger
                                                                        placement="top"
                                                                        overlay={<Tooltip id={`tooltip-${index}`}>{copied[`latestToken${index}`] ? "Copied" : "Copy to Clipboard"}</Tooltip>}
                                                                    >
                                                                        <i class="far fa-copy" onClick={() => copyTokenAddress(message.token, `latestToken${index}`)}
                                                                        ></i>
                                                                    </OverlayTrigger>
                                                                </td>
                                                                <td className={getScoreClass(message.score)} >{message.score}</td>
                                                            </tr>)
                                                        })}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={12} lg={12} xl={4} className="token-logs">
                            <div className="telegram-message">
                                <div className="telegram-inner" onScroll={onScrollTelegramMessages} ref={telegramMessagesRef}>
                                    <div className="token-logs-top">
                                        <h6>Telegram Live Messages (Solana New Liquidity Pools)</h6>
                                        <div className="selling-option-top">
                                            <h6>Stop</h6>
                                            <Form.Check
                                                type="switch"
                                                id="custom-switch"
                                                checked={telegramConnectedStatus}
                                                onChange={() => switchTelegramConnected(telegramConnectedStatus)} />
                                            <h6>Start</h6>
                                        </div>
                                    </div>

                                    {(latestTelegramMessages.length <= 0) ? Array.from({ length: 5 }).map(() => (
                                        <div className="telegram-messages-inner">
                                            <Skeleton height={15} width={500} count={20} />
                                        </div>)) :
                                        <>
                                            <div className="latest-telegram-messages-top"></div>
                                            {latestTelegramMessages?.data?.map((message, index) => {
                                                if (message.telegram_message) {
                                                    let telegramMessage = message.telegram_message ? message.telegram_message.split("\n") : [];
                                                    return (<>
                                                        {/* {index == 0 && <div className="latest-telegram-messages-top"></div>} */}
                                                        <div className="telegram-messages-inner">
                                                            {telegramMessage?.map(item => (<p>{item}</p>))}
                                                            <h6>{convertUtcToLocal(message.created_at)}</h6>
                                                        </div>

                                                    </>)
                                                }
                                            })}
                                        </>}
                                </div>
                            </div>
                        </Col>
                    </Row>

                </Container>
            </section>

            {showManualBuy && <ManualBuy showModal={showManualBuy} closeModal={setShowManualBuy} adminData={adminData} getTransactions={getTransactions} />}
            {showManualSell && <ManualSell manualSellData={manualSellData} setManualSellData={setManualSellData} showModal={showManualSell} closeModal={setShowManualSell} adminData={adminData} getTransactions={getTransactions} />}

        </>
    );
};
export default Home;