// const baseUrl = "https://snipperbot-api.ecomempire.in"
// const nodeApiUrl = "https://snipperbot-node.ecomempire.in"


// const baseUrl = "http://localhost:8000"
// const nodeApiUrl = "http://localhost:3001"


const baseUrl = "https://snipperbot-api-netset.ecomempire.in"
const nodeApiUrl = "https://snipperbot-node-netset.ecomempire.in"


export const config = {
    apiUrl: `${baseUrl}/api/v1`,
    nodeApiUrl: `${nodeApiUrl}/api/v1`,
    imageUrl: baseUrl + '/',
}

export const SOL_ADDRESS = "So11111111111111111111111111111111111111112";