import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { exceptionHandling } from "../Common/CommonComponents";
import { botApis } from "../services/botApis";
import { SOL_ADDRESS } from "../config/config";
import swal from "sweetalert";
import Loader from "../Common/Loader";
import { connectionWeb3 } from "../config/solanaWeb3";
import moment from "moment";

const ManualBuy = ({ showModal, closeModal, adminData, getTransactions }) => {
    const [manualBuyData, setManualBuyData] = useState({ amount: "", tokenAddress: "", manualTime: "", errors: {} });
    const [showLoader, setShowLoader] = useState(false);
    const { accountInfo } = connectionWeb3();
    useEffect(() => {
        setManualBuyData({ manualTime: getMinTime() })
    }, [])


    function getMinTime() {
        // const currentDate = new Date();
        // const minTime = currentDate.toISOString().slice(0, 16); // Format: YYYY-MM-DDTHH:mm
        const currentDate = moment();
        const minTime = currentDate.format('YYYY-MM-DDTHH:mm');
        return minTime;
    }

    function getMaxTime() {
        // const maxDateTime = new Date(new Date().getTime() + 2 * 24 * 60 * 60 * 1000);
        // const maxTime = maxDateTime.toISOString().slice(0, 16); // Format: YYYY-MM-DDTHH:mm
        const currentDate = moment();
        const maxTime = currentDate.add(2, 'days').format('YYYY-MM-DDTHH:mm');
        return maxTime;
    }

    function handleInputChange(event) {
        const { name, value } = event.target;
        if (name == "amount", value.length == 10) {
            return
        }
        setManualBuyData({ ...manualBuyData, [name]: value, errors: {} });
    }



    async function validateForm() {
        let error = { tokenAddress: "", amount: "" }, status = false;
        if (!manualBuyData?.tokenAddress) {
            error.tokenAddress = "Token address is required";
            status = true;
        } else {
            const tokenAccountInfo = await accountInfo(manualBuyData?.tokenAddress)
            if (!tokenAccountInfo) {
                error.tokenAddress = "Invalid token address";
                status = true;
            }
        }
        if (!manualBuyData?.amount || (Number(manualBuyData.amount) <= 0)) {
            error.amount = "Amount is required";
            status = true;
        }
        return { status, error };
    }

    async function checkTokenAddress(tokenAddress) {
        const error = { tokenAddress: "", };
        const tokenAccountInfo = await accountInfo(tokenAddress)
        if (!tokenAccountInfo) {
            error.tokenAddress = "Invalid token address";
        }
        setManualBuyData({ ...manualBuyData, errors: error });
    }

    async function buyToken() {
        const errors = await validateForm();
        if (errors.status) {
            setManualBuyData({ ...manualBuyData, errors: errors.error });
            return;
        }
        const params = {
            baseMint: SOL_ADDRESS,
            quoteMint: manualBuyData.tokenAddress,
            userId: adminData["_id"],
            manualAmount: manualBuyData.amount,
            manualTime: new Date(manualBuyData.manualTime).toUTCString(),
            isFuture : new Date(getMinTime()) < new Date(manualBuyData.manualTime),
            status: "Manual Buy"
        };
        // Make API call to buy tokens using params
        try {
            setShowLoader(true);
            // Example API call using fetch
            const response = await botApis.swapToken(params);
            if (response.status === 200) {
                await botApis.startInProcess();
                getTransactions();
                setShowLoader(false);
                swal("Success", manualBuyData.manualTime ? response.data.message : "Manual token buy successfully", "success").then(() => {
                    closeModal(false);
                })
            }
        } catch (error) {
            setShowLoader(false);
            exceptionHandling(error);
        }
    }



    return (
        <Modal show={showModal} onHide={() => closeModal(false)} centered className="manual-sell-popup">
            <Modal.Header >
                <Modal.Title>Manual Buy</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-2" controlId="formBasicPassword">
                        <Form.Label>Token Address</Form.Label>
                        <Form.Control type="text" placeholder="Enter Token Address" name="tokenAddress" value={manualBuyData.tokenAddress} onChange={handleInputChange} onBlur={(e) => checkTokenAddress(e.target.value)} />
                        {manualBuyData?.errors && manualBuyData?.errors.tokenAddress && <p className="error">{manualBuyData?.errors.tokenAddress}</p>}

                    </Form.Group>

                    <Form.Group className="mb-2" controlId="formBasicEmail">
                        <Form.Label>Manual Buy Time ( Optional )</Form.Label>
                        <Form.Control type="datetime-local"
                            min={getMinTime()}
                            // max={getMaxTime()}
                            name="manualTime"
                            value={manualBuyData.manualTime}
                            onChange={handleInputChange}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Amount</Form.Label>
                        <Form.Control type="number" min={0} placeholder="Enter Amount" name="amount" value={manualBuyData.amount} onChange={handleInputChange} />
                        {manualBuyData?.errors && manualBuyData?.errors.amount && <p className="error">{manualBuyData?.errors.amount}</p>}
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={buyToken} disabled={showLoader}>
                    {showLoader ? <Loader loaderType={"COLOR_RING"} width={25} height={25} /> : "Buy"}
                </Button>
                <Button variant="secondary" onClick={() => closeModal(false)} disabled={showLoader}>
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ManualBuy;
